'use client'

import { publicRuntimeConfig } from '@/app/util/env'
import { datadogRum } from '@datadog/browser-rum'

let env = 'dev'
let sessionSampleRate = 1

if (typeof window !== 'undefined') {
    const hostname = window.location.hostname
    if (hostname === 'giphy.com' || /media[0-4]?\.giphy\.com/.test(hostname)) {
        env = 'prod'
    } else if (hostname === 'beta.giphy.com') {
        env = 'beta'
        sessionSampleRate = 0
    } else if (hostname === 'dev.giphy.tech') {
        env = 'dev'
        sessionSampleRate = 0
    }
}

let initRUM = true
if (typeof navigator !== 'undefined') {
    const userAgent = navigator.userAgent?.toLowerCase() ?? ''

    if (
        userAgent.includes('bot') ||
        userAgent.includes('spider') ||
        userAgent.includes('crawl') ||
        userAgent.includes('naver') ||
        userAgent.includes('yeti')
    ) {
        // Do not initialize DataDog RUM for bots
        initRUM = false
    }
}

if (typeof window !== 'undefined' && initRUM) {
    datadogRum.init({
        applicationId: '2e32d33c-02cc-4220-a02c-966ed22bc5e7',
        clientToken: 'pub28375b7deaed5dfd464c0bf6a7992bf5',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.com',
        service: 'web-app-nextjs',
        env,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: publicRuntimeConfig.ddVersion,
        sessionSampleRate,
        sessionReplaySampleRate: 1,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        // traceSampleRate is for backend traces (APM) that are
        // tied to RUM sessions. This is not for browser traces.
        // This can cause a large ingest rate for APM.
        // https://docs.datadoghq.com/real_user_monitoring/platform/connect_rum_and_traces/?tab=browserrum
        traceSampleRate: 1,
        defaultPrivacyLevel: 'mask-user-input',
        beforeSend: (event) => {
            try {
                // 2025-01-08 - HH - CP-515 - Add new views to the list below
                // to exclude them from being considered as a channel
                // List of views: https://giphypedia.atlassian.net/wiki/spaces/WF/pages/3833267108/NextJS+Conversion+Status
                if (event.type === 'view') {
                    const url = new URL(event.view.url)
                    if (url.pathname.startsWith('/search/')) {
                        if (/stickers?$/.test(url.pathname) || /transparent(-gifs?)?$/.test(url.pathname)) {
                            event.view.name = '/search/[term-stickers]'
                        } else if (/clips$/.test(url.pathname)) {
                            event.view.name = '/search/[term-clips]'
                        } else if (/\/search\/\@[^-]+-.+/.test(url.pathname)) {
                            event.view.name = '/search/@[channel]-[term]'
                        } else {
                            event.view.name = '/search/[term-gifs]'
                        }
                    } else if (url.pathname.startsWith('/gifs/')) {
                        event.view.name = '/gifs/[slug]'
                    } else if (url.pathname.startsWith('/clips/')) {
                        event.view.name = '/clips/[slug]'
                    } else if (url.pathname.startsWith('/media/')) {
                        event.view.name = '/media/[slug]'
                    } else if (url.pathname.startsWith('/explore/')) {
                        if (/stickers?$/.test(url.pathname) || /transparent(-gifs?)?$/.test(url.pathname)) {
                            event.view.name = '/explore/[term-stickers]'
                        } else if (/clips$/.test(url.pathname)) {
                            event.view.name = '/explore/[term-clips]'
                        } else {
                            event.view.name = '/explore/[term-gifs]'
                        }
                    } else if (url.pathname.startsWith('/stickers/')) {
                        event.view.name = '/stickers/[slug]'
                    } else if (url.pathname.startsWith('/password/change/')) {
                        event.view.name = '/password/change/[token]'
                    } else if (
                        // Exclude all the pages that do not have parameters
                        // and that are not channels
                        !(
                            url.pathname in
                            [
                                '/404',
                                '/500',
                                '/artists',
                                '/edit/calendar',
                                '/edit/tags',
                                '/favorites',
                                '/join',
                                '/login',
                                '/password',
                                '/password/change',
                                '/trending-gifs',
                            ]
                        )
                    ) {
                        // All other pages are considered channels
                        if (/\/[^/]+\/clips$/.test(url.pathname)) {
                            event.view.name = '/[channel]/clips'
                        } else if (/\/[^/]+\/stories$/.test(url.pathname)) {
                            event.view.name = '/[channel]/explore/[term]'
                        } else if (/\/[^/]+\/search\/.+/.test(url.pathname)) {
                            event.view.name = '/[channel]/search/[term]'
                        } else if (/\/[^/]+\/tagged\/.+/.test(url.pathname)) {
                            event.view.name = '/[channel]/tagged/[tag]'
                        } else if (/\/[^/]+\/.+/.test(url.pathname)) {
                            // Note: can have multiple subchannels
                            event.view.name = '/[channel]/[...subchannels]'
                        } else {
                            event.view.name = '/[channel]'
                        }
                    }
                }
                return true
            } catch {
                // Drop the event if there is an error
                return false
            }
        },
    })
}

export function DatadogProvider() {
    // Render nothing - this component is only included so that the init code
    // above will run client-side
    return null
}
