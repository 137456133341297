import(/* webpackMode: "eager", webpackExports: ["DatadogProvider"] */ "/app/app/nextjs/app/components/datadog-rum-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/util/comscore.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/nextjs/app/util/fullstory.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/webfonts/interface-bold.woff2\",\"weight\":\"bold\"},{\"path\":\"../public/webfonts/interface-reg.woff2\",\"weight\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-interface\"}],\"variableName\":\"Interface\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/webfonts/nexa_black.woff2\",\"weight\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-nexa-black\"}],\"variableName\":\"NexaBlack\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/webfonts/ss-standard.woff2\",\"display\":\"swap\"}],\"variableName\":\"SSStandard\"}");
;
import(/* webpackMode: "eager" */ "/app/app/nextjs/app/global.css");
